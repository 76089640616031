import React, { Component } from 'react';
import Recaptcha from 'react-recaptcha'; 
import Header from './components/header.js';
import Footer from './components/footer.js';
import './contact.css';

class Contact extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
          yourname: '',
          email: '',
          subject: '',
          message: '',
          errormessage: '',
          haserrors: false,
          isVerified: false
        };
        this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    
    validateEmail(email) {
        var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return re.test(String(email).toLowerCase());
    }

    mySubmitHandler = (event) => {
        event.preventDefault();
        let errorMessage = '';
        let errorCondition = false;
        this.setState({"message": errorMessage, haserrors: false});
        if (this.state.yourname.length < 3) {
            errorMessage = errorMessage + 'The Name field must contain at least 3 characters';
            errorCondition = true;
            this.setState({errormessage:errorMessage, hasErrors: errorCondition});
        }
        if (!this.validateEmail(this.state.email) ) {
            errorMessage = errorMessage + 'The email field is invalid';
            errorCondition = true;
            this.setState({errormessage:errorMessage, hasErrors: errorCondition});
        }
        if(errorCondition) {
            return;
        }
        fetch('https://react.robmiracle.com/xyzzy/plugh/index.php', {
            method: 'POST',
            headers: {
                'Accept': 'application/x-www-form-urlencoded',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: {
                yourname: encodeURI(this.state.yourname),
                email : encodeURI(this.state.email),
                subject: encodeURI(this.state.subject),
                message: encodeURI(this.state.message)
            }
        });
        alert("You are submitting " + this.state.email);
    }

    componentDidMount() {
        if (this.captchaDemo) {
            console.log("started, just a second...")
            this.captchaDemo.reset();
            this.captchaDemo.execute();
        }    
    }

    onLoadRecaptcha() {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
            this.captchaDemo.execute();
        }
    }

    verifyCallback(recaptchaToken) {
        // Here you will get the final recaptchaToken!!!  
        console.log('recaptcha token', recaptchaToken, "<= your recaptcha token")
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
    }
    
    render() {
        return (
            <div>
                <Header />
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h2>Contact Rob</h2>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <form>
                            <label htmlFor='yourname'>Name: <span className="required">*</span></label>
                            <input
                                name="yourname"
                                type="text"
                                value={this.state.yourname}
                                size="60"
                                required
                                placeholder = "Name"
                                onChange={this.handleInputChange} />
                            <br />
                            <label htmlFor='email'>Email: <span className="required">*</span></label>
                            <input
                                name="email"
                                type="email"
                                value={this.state.email}
                                size="60"
                                required
                                placeholder = "Email Address"
                                onChange={this.handleInputChange} />
                            <label htmlFor='subject'>Subject: <span className="required">*</span></label>
                            <input
                                name="subject"
                                type="subject"
                                value={this.state.subject}
                                size="60"
                                required
                                placeholder = "Subject"
                                onChange={this.handleInputChange} />
                            <label htmlFor='message'>Message: <span className="required">*</span></label>
                            <textarea name="message" 
                                value={this.state.message} 
                                onChange={this.handleInputChange}
                                required 
                                placeholder = "Enter your message here"
                                rows="10" 
                                cols="60"
                            />
                            <Recaptcha
                                ref={(el) => {this.captchaDemo = el;}}
                                size="normal"
                                render="invisible"
                                sitekey="6LdSlLMUAAAAAG2C6tgTlV2A4GCjGnHSdXpjIq3b"
                                onloadCallback={this.onLoadRecaptcha}
                                verifyCallback={this.verifyCallback}
                            />
                            <p className="errorMessage">{this.state.errormessage}</p>
                            <button className="btn btn-primary" onClick={this.mySubmitHandler} type='submit'>Send Message</button>
                        </form>
                    </div>
                </div>
                <Footer />
            </div>
        );  
    }
}

export default Contact;