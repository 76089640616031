import React, { Component } from 'react';
import { Link } from 'react-router-dom'
//import './App.css';
import breezy from '../images/breezy.jpg';
import training from '../images/training.png';
import photography from '../images/photography.jpg';

class HomePage extends Component {
  render() {
    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-7">
                    <Link to="/webdeveloper">
                        <img className="img-fluid rounded mb-3 mb-md-0" src={breezy} alt="Bree-Zy Cleaning" /> 
                    </Link>
                </div>
                <div className="col-sm-5">
                    <Link to="/webdeveloper"><h3>Full-stack Web Developer</h3></Link>
                    <p>Exprienced web developer with a deep knowledge of client side languages including HTML5, CSS, JavaScript, JQuery, and server-side technologies like PHP, MySQL, Linux, and Apache.</p>
                    <Link to="/webdeveloper"><button className="btn btn-primary"  variant="primary">Learn more...</button></Link>
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-sm-7">
                    <Link to="/trainer">
                        <img className="img-fluid rounded mb-3 mb-md-0" src={training} alt="Professional Product Trainer" />
                    </Link>
                </div>
                <div className="col-sm-5">
                    <Link to="/trainer"><h3>Corporate Trainer and Training Developer</h3></Link>
                    <p>Experienced corporate trainer with experience in teaching computer technology to adult learners and devloping training programs for various orginizations.</p>
                    <Link to="/trainer"><button className="btn btn-primary" variant="primary">Learn more...</button></Link>
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-sm-7">
                    <Link to="/photography">
                        <img className="img-fluid rounded mb-3 mb-md-0" src={photography} alt="Professional Photographer" />
                    </Link>
                </div>
                <div className="col-sm-5">
                    <Link to="/photography"><h3>Professional Photographer</h3></Link>
                    <p>Over 35 years experience providing News, Sports, Weddings, Fashion, Corporate and Nature photography to many different clients worldwide.</p>
                    <Link to="/photography"><button className="btn btn-primary" variant="primary">Learn more...</button></Link>
                </div>
            </div>
        </div>
    );  
  }
}

export default HomePage;