import React, { Component } from 'react';
import Header from './components/header.js';
import Footer from './components/footer.js';
import code from './images/code.jpg';
import resume from './assets/rob-miracle-ats-training-resume.pdf';
import "./weather-icons.css";
import "./weather-icons-wind.css";
import "./weather.css";
import {weather_icons} from "./weather-icons.js";

class WebDeveloper extends Component {
    constructor() {
        super();
        this.state = { data: {} };

        fetch("https://api.apixu.com/v1/current.json?key=3ae68779046647549a571343172103&q=auto:ip")
            .then(response => response.json())
            .then(json => {
                this.setState({data: json})
                console.log(this.state.data.current);
                this.setState({temp_f: Math.round(this.state.data.current.temp_f)});
                this.setState({conditionText: this.state.data.current.condition.text})
                console.log(this.state.temp_f);
                for( let weather_icon of weather_icons ) {
                    if (this.state.data.current.condition.code == weather_icon.code) {
                        let today= new Date();
                        let hour = today.getHours();
                        let iconClass = "wi " + weather_icon.day;
                        if (hour < 6 || hour > 18) {
                            iconClass = "wi " + weather_icon.night;
                        }
                        this.setState({iconClass:iconClass});
                    }
                }
                const windDir = this.state.data.current.wind_degree;
                let windMPH = this.state.data.current.wind_mph;
                let windSpeed = windMPH.toString();
                if (windMPH < 1) {
                    windSpeed = "Calm";
                }
                this.setState({windDir: windDir});
                this.setState({windSpeed: windSpeed});
                let windDirClass = "wi wi-wind towards-" + windDir + "-deg";
                this.setState({windDirClass: windDirClass});

            })
            .catch(error => console.log(error));
    }
    render() {
        return (
            <div>
                <Header />
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h2>Web Developer</h2>
                        </div>
                    </div>
            
                    <hr />
            
                    <div className="row">
                        <div className="col-sm-4">
                            <img className="img-fluid rounded mb-3 mb-md-0" src={code} alt="web code" />
                            <p>&nbsp;</p>
                            <a href={resume} target="_blank" rel="noopener noreferrer"><button className="btn btn-primary">Download my resume (ATS friendly)</button></a>
                        </div>
                        <div className="col-md-8">
                            <p>The Internet feels like it's in its infancy, though the World Wide Web is now over 30 years old. During those 30 years, much has changed, but somethings remain constant. Browsers may change, the tools used to build the web change frequently, but at the core HTML, CSS and JavaScript still drive the browser experience and on the server side, to create the dynamic exerpiences, you still need a server scripting langauge that can talk to your database store. Images still have to be delivered. Your web server still has to support multiple domains.</p>
                            <p>All of this requires experience, not just with the tools of the day, but the problems that need solved are the same that they have been since Mosaid and the NCSA first servers went live.</p>
                            <p>I have been here since the beginning. I've dealt with the browser wars and the quest to get rid of IE6. I've built and maintained websites for going on 30 years. Every position since the beginning of the web that I've held has required me to have my hands in various website construction technologies. I'm fluent in HTML (and HTML5) and structuring well organized documents, I'm comfortable taking a UI design from an artist and realizing their vision. And I work well in Javascript.</p>
                            <p>You may be looking for experience in a particular framework, but those frameworks change. You weren't using Angular five years ago and you won't be five years from now. You need a web developer who can transcend tools that may come and go and adapt to your now and your future.</p>
                        </div>
                    </div>
            
                    <hr />
            
                    <div className="row">
                        <div className="col-sm-8">
                            <p>Web Development is best when your developer undertstands the full-stack. That is they can both build and utilize server-side techologies like creating RESTful API's from your database that can be consumed via your site's web scripting technology and have that output in efficient, well structured elegant front end langages that are cross-browser and mobile friendly.</p>
                            <p>Knowledge of content management systems (CMS) is also key for many customers because they don't want to think about any of this. They just want to create the content and have it displayed. I've worked for a company that built custom content management systems. I've even built my own and of course I've worked with some of the most popular CMS's like Wordpress and Drupal.</p>
                            <p>While my comfort zone on the server-side includes PHP, MySQL, and Wordpress, I'm certainly comfortable learning new technologies. I can work with technology like Node.js, Angular and React.</p>
                            <p>In my career, I've built everything from the website for a major national newspaper to personal portofolio sites for photograpers. I've built dynamic pages for a company that let the HR department use a Google spreadsheet to track employee information and transform that data to an interactive seating chart.</p>
                        </div>
                        <div className="col-sm-4 card">
                            <div id="temperature">
                                {this.state.temp_f}&deg;
                            </div>
                            <div id="conditions">
                                <div id="conditions-icon"><i className={this.state.iconClass}></i></div>
                                <div id="conditions-text">{this.state.conditionText}</div>
                                <div id="wind">
                                    <span id="wind-info">{this.state.windSpeed} mph&nbsp;</span>
                                    <span id="wind-icon"><i className={this.state.windDirClass}></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <Footer />
            </div>
        )
    }
}

export default WebDeveloper;