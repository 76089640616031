import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Header extends Component {
  render() {
    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-7">
                    <h1 className="display-4"><Link to="/"><strong>Rob Miracle</strong></Link>  <small>The Omnigeek</small></h1>
                </div>
                <div className="col-sm-5">
                    <nav className="navbar navbar-expand-sm bg-light">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link to="/about">ABOUT</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/contact">CONTACT</Link>
                            </li>
                        </ul>
                    </nav> 
                </div>
            </div>
        </div>
    );  
  }
}

export default Header;