import React, { Component } from 'react';
//import './App.css';

class Footer extends Component {
  render() {
    return (
        <div className="container">
            <hr />
            <div className="row">
                <div className="col-sm-6">            
                    <p>Copyright &copy; 2019, Rob Miracle. All Rights Reserved.</p>
                    <p>This website is built with React JS.</p>
                </div>
            </div>
        </div>
    );  
  }
}

export default Footer;