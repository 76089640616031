import React, { Component } from 'react';
import Header from './components/header.js';
import Footer from './components/footer.js';
import training from './images/training_trimmed.png';
import resume from './assets/rob-miracle-ats-training-resume.pdf';

class Trainer extends Component {
  render() {
    return (
        <div>
            <Header />
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h2>Corporate Trainer</h2>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-sm-8">
                        <p>I've accumulated a lot of complex knowledge over the years and helping others understand complex ideas in simple ways is one of my core talents.</p>
                        <p>Starting with my very first role comptuerizing a community college campus, I helped the faculty develop course material to be used on their new computer tools. Each administrative department had to be trained on the administrative software running on the same computer.</p>
                        <p>As an academic consultant and network engineer at the University of Louisville, a natural part of my role was training faculty and staff on the new computer technology they were receiving. I created formal classes for faculty and staff to take to learn the software they had been given.</p>
                    </div>
                    <div className="col-sm-4">
                        <img className="img-fluid rounded mb-3 mb-md-0" src={training} alt="Corporate Trainer" />
                        <p>&nbsp;</p>
                        <a href={resume} target="_blank" rel="noopener noreferrer"><button className="btn btn-primary align-right" target="blank">Download my training resume</button></a>
                    </div>
                </div>
   
                <div className="row">
                    <div className="col-sm-8">
                        <p>At the McClatchy Company, from the very beginning, as part of the roleout of our content management system, I had to work with the developers to create training programs that taught on-site developers how to code in our new CMS, along with web development best practices. Eventually this training also involved to include Journalists, Editors and Photographers at over 30 newspapers around the country.</p>
                        <p>During this tenure, we ended up replacing the CMS again, and had to repeat the process. Not only did this involve in-class room training, course design and managing a small team of trainers, it also included the project management necessary to roll out this complex system to multiple stakeholders in the most efficient manner possible.</p>
                        <p>Today as the Developer Relations Manager for a game developmement engine, I'm responsible to make sure our customers, all developers know how to use our engine and have to produce tutorials and other training materials for them and work with them to make sure they achieve their success.</p>
                        <p>I can work with any subject matter expert (or become the subject matter expert) and create effective training for you.</p>
                    </div>
                    <div className="col-sm-4" >
                    </div>            
                </div>
            </div>
            <Footer />
        </div>
    )
  }
}

export default Trainer;