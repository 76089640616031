import React, { Component } from 'react';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import Header from './components/header.js';
import Footer from './components/footer.js';
import lions from './images/lions.jpg';
import hockey from './images/hockey.jpg';
import cougar from './images/cougar.jpg';
import horses from './images/horses.jpg';
import swans from './images/chinese_swans.jpg'
import coldMorning from './images/cold_morning.jpg';
import miamiSkyline from './images/miami_skyline.jpg';
import sailboat from './images/sailboat.jpg';
import sunriseBalloons from './images/sunrise_balloons.jpg';
import surferSuprise from './images/surfer_surprise.jpg';
import yosemiteValley from './images/yosemite_valley.jpg';
import lakeLure from './images/lake_lure.jpg';
import cowboys from './images/cowboys_v_redskins.jpg';
import momAndFoal from './images/mom_and_foal.jpg';
import halfdome from './images/hiking_halfdome.jpg';
import goldenGate from './images/golden_gate_bridge2.jpg';
import suprisedSquirrel from './images/surprised_squirrel.jpg';


import budgie from './images/BudgieParakeet02.jpg';
import cardinal from './images/CardinalEating02.jpg';
import landscape from './images/landscape.jpg';
import goldfinch from './images/GoldFinch01.jpg';
import whiteTiger from './images/white_tiger.jpg';
import miamiSunrise from './images/miami_sunrise.jpg';
import seal from './images/feeding_happiness.jpg';
import butterfly from './images/butterfly.jpg';
import lighthouseSunset from './images/lighthouse_sunset.jpg';
import yosemiteSunset from './images/yosemite_sunset.jpg';

let images = []

function generateCarouselItems() {

}

class Photography extends Component {
  render() {
    return (
        <div>
            <Header />
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <Carousel>
                            <Carousel.Item>
                                <img 
                                    className="d-block w-100"
                                    src={cowboys}
                                    alt="NFL Football - Dallas at Washington"
                                />
                                <Carousel.Caption>
                                    <h3>NFL Football</h3>
                                    <p>Washington, DC</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img 
                                    className="d-block w-100"
                                    src={lakeLure}
                                    alt="Lake Lure, NC"
                                />
                                <Carousel.Caption>
                                    <h3>Sunset over the Lake</h3>
                                    <p>Lake Lure, NC</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img 
                                    className="d-block w-100"
                                    src={yosemiteValley}
                                    alt="Yosemite Valley"
                                />
                                <Carousel.Caption>
                                    <h3>Yosemite Vally</h3>
                                    <p>Yosemite National Park, CA</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img 
                                    className="d-block w-100"
                                    src={sunriseBalloons}
                                    alt="Balloons at Sunrise"
                                />
                                 <Carousel.Caption>
                                    <h3>Kentucky Derby Festival Great Balloon Race</h3>
                                    <p>Louisville, KY</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img 
                                    className="d-block w-100"
                                    src={surferSuprise}
                                    alt="Surfer Suprise"
                                />
                                <Carousel.Caption>
                                    <h3>Surfer Surpise</h3>
                                    <p>Marrow Bay, CA</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img 
                                    className="d-block w-100"
                                    src={momAndFoal}
                                    alt="Mom and Foal"
                                />
                                <Carousel.Caption>
                                    <h3>Mom and Foal</h3>
                                    <p>Lexington, KY</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img 
                                    className="d-block w-100"
                                    src={lions}
                                    alt="Lion Love"
                                />
                                <Carousel.Caption>
                                    <h3>Lion Love</h3>
                                    <p>Miami, FL</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                           <Carousel.Item>
                                <img 
                                    className="d-block w-100"
                                    src={sailboat}
                                    alt="Sail boat"
                                />
                                <Carousel.Caption>
                                    <h3>Schooner</h3>
                                    <p>Bellingham, WA</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img 
                                    className="d-block w-100"
                                    src={miamiSkyline}
                                    alt="Miaml Skyline"
                                />
                                <Carousel.Caption>
                                    <h3>Miami Skyline</h3>
                                    <p>Miami, FL</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img 
                                    className="d-block w-100"
                                    src={swans}
                                    alt="Chinese Lantern Swans"
                                />
                                <Carousel.Caption>
                                    <h3>Swans - Chinese Lantern Festival</h3>
                                    <p>Cary, NC</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img 
                                    className="d-block w-100"
                                    src={halfdome}
                                    alt="Hiking Half Dome"
                                />
                                <Carousel.Caption>
                                    <h3>Hiking Half Dome</h3>
                                    <p>Yosemite National Park, CA</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img 
                                    className="d-block w-100"
                                    src={coldMorning}
                                    alt="Cold morning at Churchill Downs"
                                />
                                <Carousel.Caption>
                                    <h3>Cold morning at Churchill Downs</h3>
                                    <p>Louisville, KY</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img 
                                    className="d-block w-100"
                                    src={goldenGate}
                                    alt="Golden Gate Bridge"
                                />
                                <Carousel.Caption>
                                    <h3>Golden Gate Bridge</h3>
                                    <p>San Fransisco, CA</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                        <Carousel.Item>
                                <img 
                                    className="d-block w-100"
                                    src={suprisedSquirrel}
                                    alt="Common Gray Squirrel"
                                />
                                <Carousel.Caption>
                                    <h3>Common Gray Squirrel</h3>
                                    <p>Cary, NC</p>
                                </Carousel.Caption>
                            </Carousel.Item>

                    </div>
                </div>

                <hr />

                <div className="row">
                    <div className="col-sm-12">
                        <h3><a href="https://www.flickr.com/photos/miracle_man" target="_blank">See more of Rob's Photography</a> <i className="fa fa-flickr" aria-hidden="true"></i></h3>
                    </div>
                </div>

                <hr />

                <div className="row">
                    <div className="col-sm-4">
                        <a href="#">
                            <img className="img-fluid" src={hockey} alt="Editorial and Sports Photography" />
                        </a>
                        <h3>Editorial and Sports Photography</h3>
                        <p>From breaking news to professional sports to your Mite hockey team, Rob has the experience to capture anything that moves and capture the defining moment of the event.</p>
                    </div>
                    <div className="col-sm-4">
                        <a href="#">
                            <img className="img-fluid rounded mb-3 mb-md-0" src={cougar} alt="Nature and Travel Photoghraphy" />
                        </a>
                        <h3>Nature &amp; Travel Photography</h3>
                        <p>I'm also an experienced wildlife, nature and travel photographer. I'm available for contract on any of your needs. Need photos from a local or looking for great wildlife photos, just reach out!</p>
                    </div>
                    <div className="col-sm-4">
                        <a href="#">
                            <img className="img-fluid rounded mb-3 mb-md-0" src={horses} alt="Equestrian Photography" />
                        </a>
                        <h3>Equestrian Photography</h3>
                        <p>If you're into equestrian and need photos, I'm your photographer. With years of experience photographing sports and a passion for horses, it's a combination that will result in extraordinary photos for you. Have a horse that you need photos of? Photos of you with your horse? Contact us!</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        )
    }
}
  
export default Photography;