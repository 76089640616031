import React, { Component } from 'react';
import Header from './components/header.js';
import Footer from './components/footer.js';
//import './App.css';
import me from './images/me.jpg';
import atsResume from "./assets/rob-miracle-ats-resume.pdf";
import fullResume from "./assets/rob-miracle-full-resume.pdf";

class About extends Component {
  render() {
    return (
        <div>
            <Header />
            <div className="container">
                <div class="row">
                    <div class="col-sm-7">
                        <h2>About me!</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">

                            <img class="img-fluid rounded mb-3 mb-md-0" src={me} alt="Rob Miracle" />
 
                        <p>&nbsp;</p>
                        <a class="btn btn-primary" href={atsResume} target="blank">Download my resume (ATS friendly)</a>
                        <p>&nbsp;</p>
                        <a class="btn btn-primary" href={fullResume} target="blank">Download my resume with full descriptions</a>
                    </div>
                    <div class="col-sm-8">
                        <h3>Fun facts with Rob</h3>
                        <dt>Why are you the Omnigeek?</dt>
                        <dd>To me a geek is someone who is overly passionate about a particular subject. I mean you can be a sci-fi geek, or a fantasy geek, but you can also be a sports geek or a knitting geek too. I geek out over many things... too many to keep track of, so I like to think of myself as the geek of all things!</dd>
                        <dt>Sports are a big part of my life</dt>
                        <dd>While I don't play many any longer, I'm a huge sports fan, putting Hockey, Horse Racing and Football at the top of my list, but I can talk all of the sports with you.</dd>
                        <dt>What do you do for fun</dt>
                        <dd>I've been blessed with occupations which rank at the top of my fun list. I love to program. I love to create something from nothing. Most of my career has involved programming at some level. I don't get up to go to work in the morning. I get up to create something new. Of course Photography has to be right up there. Beyond that I enjoy hiking and watching a good movie and spending time with my awesome family.</dd>
                        <dt>What are you the worst at?</dt>
                        <dd>Oh that's an easy one... plumbing. I'm so bad at it I can't hook up a water hose without it leaking... I guess I need to become more of a fluid dynamics geek.</dd>
                        <dt>Where is your favorite place?</dt>
                        <dd>That's another easy one. I love Yosemite National Park. That place is just magical. </dd>
                        <dt>What is your favorite thing you've done?</dt>
                        <dd>Spending time with my wife and raising to fantastic boys is there, but need to be at the top of everyone's list. But personally, getting the opportunity to photograph the Kentucky Derby has to be my #1, non-family memory.</dd>
                        <h3>What about your work style?</h3>
                        <p>I would like to think that while being a team-player, I can also work independently and am a self-starter. One of my previous supervisors paid me a great complement saying that I almost always have things done before he can ask me to do them. I have the exeprience to know what needs to be done and work to get them done before they become pressure points.</p>
                        <p>I'm confident and when I don't know what I need, I know where to go to get the information and I'm proactive about learning it. It's these traits, along with my desire to share information as to why I've been successful as a trainer. I want to learn all that I can learn and I want to share that. For those who have seen some of my social media profiles, my quest is to become a super-genius. As I said earlier I geek about everything I can! I am the Omnigeek!</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );  
  }
}

export default About;