export const weather_icons = [
    {
        "code" : 1000,
        "day" : "wi-day-sunny",
        "night" : "wi-night-clear",
        "icon" : 113
    },
    {
        "code" : 1003,
        "day" : "wi-day-cloudy",
        "night" : "wi-night-cloudy",
        "icon" : 116
    },
    {
        "code" : 1006,
        "day" : "wi-cloudy",
        "night" : "wi-cloudy",
        "icon" : 119
    },
    {
        "code" : 1009,
        "day" : "wi-day-sunny-overcast",
        "night" : "wi-night-partly-cloudy",
        "icon" : 122
    },
    {
        "code" : 1030,
        "day" : "wi-day-sprinkle",
        "night" : "wi-night-alt-sprinkle",
        "icon" : 143
    },
    {
        "code" : 1063,
        "day" : "wi-rain",
        "night" : "wi-night-alt-rain",
        "icon" : 176
    },
    {
        "code" : 1066,
        "day" : "wi-snow",
        "night" : "wi-night-alt-snow",
        "icon" : 179
    },
    {
        "code" : 1069,
        "day" : "wi-sleet",
        "night" : "wi-night-alt-sleet",
        "icon" : 182
    },
    {
        "code" : 1072,
        "day" : "wi-day-sleet",
        "night" : "wi-night-alt-sleet",
        "icon" : 185
    },
    {
        "code" : 1087,
        "day" : "wi-day-thunderstorm",
        "night" : "wi-night-alt-tunderstorm",
        "icon" : 200
    },
    {
        "code" : 1114,
        "day" : "wi-snow-wind",
        "night" : "wi-night-alt-snow-wind",
        "icon" : 227
    },
    {
        "code" : 1117,
        "day" : "wi-snow-wind",
        "night" : "wi-night-alt-snow-wind",
        "icon" : 230
    },
    {
        "code" : 1135,
        "day" : "wi-day-fog",
        "night" : "wi-night-fog",
        "icon" : 248
    },
    {
        "code" : 1147,
        "day" : "wi-day-fog",
        "night" : "wi-night-fog",
        "icon" : 260
    },
    {
        "code" : 1150,
        "day" : "wi-day-sprinkle",
        "night" : "wi-night-alt-sprinkle",
        "icon" : 263
    },
    {
        "code" : 1153,
        "day" : "wi-day-sprinkle",
        "night" : "wi-night-alt-sprinkle",
        "icon" : 266
    },
    {
        "code" : 1168,
        "day" : "wi-day-sleet",
        "night" : "wi-night-alt-sleet",
        "icon" : 281
    },
    {
        "code" : 1171,
        "day" : "wi-day-sleet",
        "night" : "wi-night-alt-sleet",
        "icon" : 284
    },
    {
        "code" : 1180,
        "day" : "wi-day-sprinkle",
        "night" : "wi-night-alt-sprinkle",
        "icon" : 293
    },
    {
        "code" : 1183,
        "day" : "wi-day-rain",
        "night" : "wi-night-alt-rain",
        "icon" : 296
    },
    {
        "code" : 1186,
        "day" : "wi-day-rain",
        "night" : "wi-night-alt-rain",
        "icon" : 299
    },
    {
        "code" : 1189,
        "day" : "wi-day-rain",
        "night" : "wi-night-alt-rain",
        "icon" : 302
    },
    {
        "code" : 1192,
        "day" : "wi-day-rain",
        "night" : "wi-night-alt-rain",
        "icon" : 305
    },
    {
        "code" : 1195,
        "day" : "wi-day-rain",
        "night" : "wi-night-alt-rain",
        "icon" : 308
    },
    {
        "code" : 1198,
        "day" : "wi-day-sleet",
        "night" : "wi-night-alt-sleet",
        "icon" : 311
    },
    {
        "code" : 1201,
        "day" : "wi-day-sleet",
        "night" : "wi-night-alt-sleet",
        "icon" : 314
    },
    {
        "code" : 1204,
        "day" : "wi-day-sleet",
        "night" : "wi-night-alt-sleet",
        "icon" : 317
    },
    {
        "code" : 1207,
        "day" : "wi-day-sleet",
        "night" : "wi-night-alt-sleet",
        "icon" : 320
    },
    {
        "code" : 1210,
        "day" : "wi-day-snow",
        "night" : "wi-night-alt-snow",
        "icon" : 323
    },
    {
        "code" : 1213,
        "day" : "wi-day-snow",
        "night" : "wi-night-alt-snow",
        "icon" : 326
    },
    {
        "code" : 1216,
        "day" : "wi-day-snow",
        "night" : "wi-night-alt-snow",
        "icon" : 329
    },
    {
        "code" : 1219,
        "day" : "wi-day-snow",
        "night" : "wi-night-alt-snow",
        "icon" : 332
    },
    {
        "code" : 1222,
        "day" : "wi-day-snow",
        "night" : "wi-night-alt-snow",
        "icon" : 335
    },
    {
        "code" : 1225,
        "day" : "wi-day-snow",
        "night" : "wi-night-alt-snow",
        "icon" : 338
    },
    {
        "code" : 1237,
        "day" : "wi-day-sleet",
        "night" : "wi-night-alt-sleet",
        "icon" : 350
    },
    {
        "code" : 1240,
        "day" : "wi-day-rain",
        "night" : "wi-night-alt-rain",
        "icon" : 353
    },
    {
        "code" : 1243,
        "day" : "wi-day-rain",
        "night" : "wi-night-alt-rain",
        "icon" : 356
    },
    {
        "code" : 1246,
        "day" : "wi-day-rain",
        "night" : "wi-night-alt-rain",
        "icon" : 359
    },
    {
        "code" : 1249,
        "day" : "wi-day-sleet",
        "night" : "wi-night-alt-sleet",
        "icon" : 362
    },
    {
        "code" : 1252,
        "day" : "wi-day-sleet",
        "night" : "wi-night-alt-sleet",
        "icon" : 365
    },
    {
        "code" : 1255,
        "day" : "wi-day-snow",
        "night" : "wi-night-alt-snow",
        "icon" : 368
    },
    {
        "code" : 1258,
        "day" : "wi-day-snow",
        "night" : "wi-night-alt-snow",
        "icon" : 371
    },
    {
        "code" : 1261,
        "day" : "wi-day-sleet",
        "night" : "wi-night-alt-sleet",
        "icon" : 374
    },
    {
        "code" : 1264,
        "day" : "wi-day-sleet",
        "night" : "wi-night-alt-sleet",
        "icon" : 377
    },
    {
        "code" : 1273,
        "day" : "wi-day-rain",
        "night" : "wi-night-alt-rain",
        "icon" : 386
    },
    {
        "code" : 1276,
        "day" : "wi-day-rain",
        "night" : "wi-night-alt-rain",
        "icon" : 389
    },
    {
        "code" : 1279,
        "day" : "wi-day-snow",
        "night" : "wi-night-alt-snow",
        "icon" : 392
    },
    {
        "code" : 1282,
        "day" : "wi-day-snow",
        "night" : "wi-night-alt-snow",
        "icon" : 395
    }
];